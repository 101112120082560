<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
    checked: {
        type: [Array, Boolean, Number, String],
        default: false,
    },
    disabled: {
        type: [Array, Boolean, Number, String],
        default: false,
    },
    value: {
        default: null,
    },
    label: String,
    id: String,
});

const proxyChecked = computed({
    get() {
        if (typeof props.checked === 'string') {
            return props.checked === '1';
        }

        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});
</script>


<template>
    <div class="flex flex-row items-center gap-2">
        <input
            :id="id"
            v-model="proxyChecked"
            type="checkbox"
            :disabled="disabled"
            :value="value"
            class="rounded border-gray-300 text-primary shadow-sm focus:ring-teal-300"
        >
        <label :for="id" v-show="label" class="text-slate-700 text-sm font-medium leading-tight">{{ label }}</label>
    </div>
</template>
